import React from "react";
// Customizable Area Start
import { StyleSheet, Text, View, Image, ImageBackground } from "react-native";
import { Container, Button, Grid, Tooltip, Box, Dialog,DialogContent,DialogContentText, Typography, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import LandingPageController, {
  Props,
  configJSON
} from "./LandingPageController.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  imgLogo,
  createImage,
  imgUser,
  imgNotification,
  downArrow,
  imgClock,
  imgRequestQuoteBg,
  imgEnquire, group_plus,
  closeBtnIcon
} from "./assets";
import ClearIcon from '@material-ui/icons/Clear';
import { loadingImg, iconUpArrow } from "../../customform/src/assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#F7F6FF",
      contrastText: "#fff"
    }
  }
});

// Customizable Area End

export default class LandingPageWeb extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  commonGrids () {
    return(
    <>
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <View style={{ position: "relative" }}>
          <ImageBackground
            source={imgRequestQuoteBg}
            resizeMode="cover"
            style={styles.cardLayout}
          >
            <div className="blue-gradient" />
            <View style={{ padding: 20 }}>
              <Text
                style={{
                  color: "#fff",
                  fontSize: 14,
                  fontWeight: "700",
                  marginBottom: 10,
                  fontFamily: "CircularStdBold"
                }}
              >
                {configJSON.request}
              </Text>
              <Text
                style={{
                  color: "#fff",
                  fontSize: 30,
                  fontWeight: "700",
                  fontFamily: "CircularStd",
                  marginBottom: 15
                }}
              >
                {configJSON.quote}
              </Text>
              <div className="customWraper">
                <div className="customFormOutline" />
                <SubmitButton
                  data-testid={"startNow"}
                  onClick={this.goToCategory}
                >
                  {configJSON.startNowBtn}
                </SubmitButton>
              </div>
            </View>
          </ImageBackground>
        </View>
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <View style={[styles.cardLayout, { overflow: "hidden" }]}>
          <Image
            source={imgEnquire}
            resizeMode="cover"
            style={styles.imgEnquire}
          />
          <View
            style={{
              paddingRight: 20,
              paddingBottom: 22,
              paddingTop: 7,
              paddingLeft: 20
            }}
          >
            <View
              style={[
                styles.flexColumn,
                { justifyContent: "space-between" }
              ]}
            >
              <Box style={cardSubTiitleStyle}>
                {configJSON.enquires} ({this.state.Enquires})
              </Box>
            </View>
            <div className="customWraper" style={{ marginRight: 6 }}>
              <div
                className="customFormOutline"
                style={{ right: -6 }}
              />
              <SubmitButton
                data-testid={"btnEmailLogIn"}
                onClick={this.goToEnquiry}
              >
                {configJSON.viewBtn}
              </SubmitButton>
            </div>
          </View>
        </View>
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <View 
        style={[styles.cardLayout, { overflow: "hidden" }]}>
          <ImageBackground source={imgEnquire} resizeMode="cover" style={styles.imgEnquire}/>
          <View
            style={{
              paddingRight: 20,paddingBottom: 22,   
              paddingLeft: 20,paddingTop: 7 }}
          >
            <View
              style={[
                styles.flexColumn,
                { 
                  justifyContent: "space-between" 
                }
              ]}
            >
              <Box style={cardSubTiitleStyle}>
                {configJSON.approvedEnquiries} ({this.state.ApprovedEnquiries})
              </Box>
            </View>
            <div className="customWraper" style={{ marginRight: 6 }}>
              <div
                className="customFormOutline" style={{ right: -6 }}
              />
              <SubmitButton
                data-testid={"btnApprovedEnq"} onClick={this.goToApprovedEnquires}
              >
                {configJSON.viewBtn}
              </SubmitButton>
            </div>
          </View>
        </View>
      </Grid>
    </>
  )};
  
  adminGrids () {
  return(
    <>
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <View style={[styles.cardLayout, { overflow: "hidden" }]}>
          <ImageBackground
            source={imgEnquire}
            resizeMode="cover"
            style={styles.imgEnquire}
          />
          <View
            style={{
              paddingRight: 20,
              paddingBottom: 22,
              paddingLeft: 20,
              paddingTop: 7,
            }}
          >
            <View
              style={[
                styles.flexColumn,
                { justifyContent: "space-between" }
              ]}
            >
              <Box style={cardSubTiitleStyle}>
                Invoices ({this.state.Invoices})
              </Box>
            </View>
            <div className="customWraper" style={{ marginRight: 6 }}>
              <div
                className="customFormOutline"
                style={{ right: -6 }}
              />
              <SubmitButton
                data-testid={"btnInVoices"}
                onClick={this.goToInvoicePage}
              >
                {configJSON.viewBtn}
              </SubmitButton>
            </div>
          </View>
        </View>
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={4}>
        <View style={[styles.cardLayout, { 
          overflow: "hidden" }]}>
          <ImageBackground
            source={imgEnquire}
            resizeMode="cover" style={styles.imgEnquire}
          />
          <View
            style={{
              paddingRight: 20,paddingLeft: 20,
              paddingBottom: 22,
              paddingTop: 7
            }}
          >
            <View
              style={
                [
                styles.flexColumn,
                { justifyContent: "space-between" }
              ]}
            >
              <Box style={cardSubTiitleStyle}>
                Team Members ({this.state.teamMembers})
              </Box>
            </View>
            <div className="customWraper" style={{ marginRight: 6 }}>
              <div
                className="customFormOutline"
                style={{ right: -6 }}
              />
              <SubmitButton
                data-testid={"btnTeamMembers"}
                onClick=
                {this.goToTeamMembersPage}
              >
                {configJSON.viewBtn}
              </SubmitButton>
            </div>
          </View>
        </View>
      </Grid>
    </>
  )};

  renderStatusColor(status: string) {
    let color = "#5A5A5A";
    if (status === "approved") {
      color = "#00BFBA";
    } else if (status === "rejected") {
      color = "#FF5B74";
    }
    return color;
  }

  renderUserRole = () => {
    return (localStorage.getItem("role") as string) || "defaultUserRole"
  }

  getHeight = (notificationData: any, isOpenNotification: any) => {
    if (notificationData.length <= 0 && isOpenNotification) {
      return 275;
    }else if (notificationData.length > 0 && !isOpenNotification && this.state.isCloseNotification) {
      return 695;
    }else if (!isOpenNotification) {
      return 140;
    } else {
      return 367;
    }
  };

  calculateHeight = () => {
    const { notificationData, isOpenNotification, isCloseNotification } = this.state;
    
    if (notificationData.length > 0 && !isOpenNotification && this.state.isCloseNotification) {
      return 500;
    } else if (notificationData.length > 0 && isOpenNotification && !this.state.isCloseNotification) {
      return 180;
    } else if (notificationData.length < 0 && !isCloseNotification) {
      return 0;
    } else {
      return 100;
    }
  };

  renderLoadingComponent = (isLoading: boolean) => {
    return isLoading &&  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',width:"100%", backgroundColor: 'transparent' }}>
       <img src={loadingImg}  style={{ paddingTop: "10%", display: 'flex', justifyContent: 'center', alignItems: 'center',height:"40%",width:"12%"}}/>
  </div>
  }

  getPaddingTop = (isOpenNotification: boolean, notificationData: any) => {
    if (isOpenNotification && !notificationData.length) {
      return 0;
    }
    return isOpenNotification ? 5 : 18;
  };

 renderProfileImage = () => {
  const { companyProfilePicture, userProfileDatafirstName } = this.state;

  if (companyProfilePicture) {
    return (
                    <img
                        data-testid="companyprofile"
                                    src={this.state.companyProfilePicture}
                                    alt="profile"
                                    width="66"
                                    height="66"
                                    
                                    style={{
                                      
                                        border: "3px solid #000",
                                        width:66,
                                        height:66, 
                                        objectFit: "cover" 
                                    }}
                                    data-test-id="profileImage"
                                />
    );
  } else {
    return (
                                     <CustomCompanyLogoDiv>{userProfileDatafirstName.slice(0,1)}</CustomCompanyLogoDiv>
    );
  }
};

  getInquiryStatus = (data: any) => {
    if (data.attributes.headings === "Enquiry cost Update") {
      return "Cost Updated";
    }
    return data.attributes.inquiry_status === "partial_approved" ? "Review Quote" : data.attributes.inquiry_status;
  };

  getNotificationTitle = (data: any) => {
    return data.attributes.headings === "email_update" 
      ? data.attributes.account_holder_name 
      : data.attributes.event_name;
  };
  
  getNotificationDescription = (data: any) => {
    return data.attributes.headings === "email_update" 
      ? "Email changed" 
      : data.attributes.service_name;
  };
  
  getTooltipProps = (title:any) => {
    if(!title) return
    return {
        title: title.length > 35 ? title : "",
        disableHoverListener: title.length <= 35,
    };
  }

  renderNotificationItem = (data: any) => {
    const isRead = data.attributes.is_read === true;
    const bgColor = isRead ? "#ffffff" : "#dedcfd";
    const inquiryStatus = this.getInquiryStatus(data);
    const title = this.getNotificationTitle(data)
    return   <NotificationLayout 
                          style={{cursor: "pointer" ,backgroundColor:bgColor }} 
                          data-testid="view-enquiry" 
                          onClick={() => this.readNotification(data.attributes.id, data.attributes.inquiry_id, data.attributes.headings, data.attributes.account_holder_id)}>
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Image
                            source={
                              this.selectViewProfile()
                            }
                            style={styles.imageBorder}
                          />
                          <View
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              flex: 1,
                              flexDirection: "row",
                              width:"calc(100% - 70px)"
                            }}
                          >
                            <Box sx={webStyles.notificationContentWrap}>
                                <Tooltip placement="top" title={this.getTooltipProps(title)?.title}>
                                    <Typography style={webStyles.notificationTitle}>
                                      {this.getNotificationTitle(data)}
                                    </Typography>
                                </Tooltip>
                                <Typography style={webStyles.notificationBodyText} data-test-id="emailChangedText">
                                  {this.getNotificationDescription(data)}
                                </Typography>
                                <Typography style={webStyles.notificationBodyText}>
                                  {this.formatDate(data.attributes.updated_at)}
                                </Typography>
                            </Box>
                            <View>
                              <Box sx={approvedStyle} data-testid="inqStatus" style={{color: this.renderStatusColor(data.attributes.inquiry_status)}}>
                                {inquiryStatus}
                              </Box>
                              <View
                                style={{
                                  marginTop: "auto",
                                  justifyContent: "flex-end",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center"
                                }}
                              >
                                <Image
                                  source={{ uri: imgClock }}
                                  style={{ height: 15, width: 12 }}
                                />
                                <Text
                                  style={{
                                    color: "#64748B",
                                    fontSize: 12,
                                    marginRight: 0,
                                    height: 9,
                                    marginLeft: 5,
                                    lineHeight: 10,
                                    fontFamily: "CircularStd"
                                  }}
                                >
                                  {this.getRelativeTime(data.attributes.updated_at)}
                                </Text>
                              </View>
                            </View>
                          </View>
                        </View>
                      </NotificationLayout>
  } 


  renderNotificationLayout = (notificationData: any) => {
    if(notificationData.length > 0 ){
      return notificationData.map((data: any) => this.renderNotificationItem(data));
    }

    if(!this.state.isCloseNotification){
      return <div>
      <Text style={styles.noNotificationtext} data-test-id = "noNotificationtext">No notifications are available yet. 
        You will be notified once there are actions requiring your attention.</Text>
    </div>
    }
  }

  renderNotificationContainer = (notificationData: any, isOpenNotification: boolean) => {
    if(notificationData.length <= 0 && !isOpenNotification ) return null
    return  <>
                      <div
                        style={{
                          cursor: "pointer",
                          textAlign: "end",
                          marginTop: 30,
                          marginBottom: 10,
                          marginRight: 10,
                          fontWeight: 700
                        }}
                        onClick={() => this.clearAllNotifications()}
                        data-test-id="clearAll"
                      >
                        <Typography style={{
                          color: notificationData.length <= 0 ? "#C0C0C0" : "#594FF5",
                          fontSize: 14,
                          fontFamily: "CircularStd",
                          fontWeight: 700,
                          cursor: 'pointer',
                          marginRight: 2
                        }}>Clear All
                        </Typography>
                      </div>

                      <div style={{display:"flex", overflow:"auto", flexDirection: "column", height: this.calculateHeight(), scrollbarWidth: 'none',msOverflowStyle: 'none', margin:"0px 5px"}}>
                      {this.renderNotificationLayout(notificationData)}
                      </div>
                      </>   
  }

  renderArrowIcon = () => {
    if(this.state.notificationData.length > 0 && !this.state.isOpenNotification && this.state.isCloseNotification){
      return <div data-test-id="upArrow">
      <img src={iconUpArrow} onClick={this.handleToggleIsOpenNotification} style={{ height: 10, paddingTop: 6 }} />
    </div>
    }
      return <Image data-testid="downArrow"
      source={{
        uri: downArrow
      }}
      style={{ width: 20, height: 10 }}
    />           
  }

  renderHomePageComponent = (notificationData: any, isOpenNotification: boolean, userRole: any, isLoading: boolean) => {
    if(isLoading) return null
    return (
      <View
            style={{
              paddingTop: 40,
              paddingBottom: 40, 
              paddingRight: 20,
              paddingLeft: 20
            }}
          >
            <Container
              disableGutters
              maxWidth={false}
              style={{ paddingLeft: 45, paddingRight: 60, marginBottom: 35 }}
            >
              <Text
                style={{
                  color: "#594FF5",
                  marginBottom: 40,
                  fontFamily: "CircularStd, sans-serif"
                }}
              >
                Home
              </Text>
            </Container>
            <div className="mainWrapper">
              <View style={{paddingLeft:"0"}}>
                <Grid container spacing={0}>
                  <Grid
                    item
                   sm={6} 
                   xs={12} 
                   md={3}
                   style={{height: this.getHeight(notificationData, isOpenNotification),  
                    borderColor: "#000",
                    borderStyle: "solid",
                    borderWidth: 3,
                    marginBottom: "10px",
                    backgroundColor: '#FFFFFF'
                  }}
                  >
                    <View>
                      <View
                        style={{
                          display: "flex",
                          borderRadius: 5,
                          flexDirection: "row",
                          alignItems: "center",
                          paddingLeft: 26,
                          paddingRight: 6,
                          paddingTop: 20,
                          backgroundColor:"#ffffff"
                        }}
                      >
                        {this.renderProfileImage()}
                        <View style={{ marginLeft: 20 , backgroundColor:"#ffffff"}}>
                          <Box
                            sx={titleStyle}
                            data-test-id="testJobTitle"
                          >
                            <Text style={styles.profileTitle}>Hi {this.state.userProfileDatafirstName}</Text>
                            <Tooltip placement="top" title={this.state.userProfileDataJobTitle}>
                            <SubTitle>
                              {this.getJobTitle(this.state.userProfileDataJobTitle)}
                            </SubTitle>
                            </Tooltip>
                          </Box>
                        </View>
                      </View>
                    {this.renderNotificationContainer(notificationData, isOpenNotification)}
                    <Box data-test-id="iconArrow" style={{cursor: "pointer"}} onClick={this.handleToggleIsOpenNotification}>
                     <View
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: this.getPaddingTop(isOpenNotification, notificationData),
                          paddingBottom: 10
                        }}
                      >
                        { this.renderArrowIcon() }
                      </View>
                    </Box>
                    </View>
                  </Grid>
                  <Grid item xs={9} style={{ paddingLeft: 10 }}>
    <Grid spacing={1} container>
      {userRole === 'ClientUser' && this.commonGrids()}
      {userRole === 'ClientAdmin' && (
        <>
          {this.commonGrids()}
          {this.adminGrids()}
        </>
      )}
    </Grid>
  </Grid>
                </Grid>
              </View>
            </div>
          </View>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const userRole = this.renderUserRole();

    const { isLoading, notificationData, isOpenNotification } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container disableGutters maxWidth={false}>
          <AppHeader
            logoUrl={imgLogo}
            homeText="Home"
            createImageUrl={createImage}
            helpCenterText="Help Centre"
            userUrl={imgUser}
            settingsIcon
            helpCenterNotificationUrl={imgNotification}
            onPressHome={() => this.refreshHome()}
            onClickViewProfile={() => this.goToProfileLandingPage()}
            onClickSettings={()=> this.goToSettingsLandingPage()}
            onClickViewEnquiries={()=> this.goToEnquiry()}
            onClickQuote={()=> this.goToQuote()}
            quoteUrl={group_plus}
            onClickHelpCenter={() => this.goToHelpCenterLandingPage()}
            data-test-id="testAppHeader"
          />
          {this.renderLoadingComponent(isLoading)}
          {this.renderHomePageComponent(notificationData, isOpenNotification, userRole, isLoading)}
          <Dialog
            open={this.state.isSuspended}
            onClose={this.handleGoToLoginPage}
            aria-labelledby="suspended-account-dialoge"
            aria-describedby="suspended-account-dialoge-description"
            data-test-id="suspendedModal">
            <Box style={accountSuspendedModal.container} >
              <IconButton
                disableTouchRipple
                disableRipple
                disableFocusRipple
                data-test-id="suspendedModalCloseBtn"
                onClick={this.handleGoToLoginPage}
                style={accountSuspendedModal.crossMarkCloseBtn}>
                <img src={closeBtnIcon}/>
              </IconButton>
              <Box style={accountSuspendedModal.modalContentWrapper}>
                <Box style={accountSuspendedModal.crossMark}>
                  <ClearIcon style={accountSuspendedModal.crossMarkIcon} />
                </Box>
                <Typography style={accountSuspendedModal.suspendedTitle}>
                  {this.state.suspendedTitle}
                </Typography>
                <DialogContent>
                  <DialogContentText
                    style={accountSuspendedModal.suspendedMessage}
                    id="suspended-account-dialoge-description"
                    data-test-id="suspendedMessageID">
                    {this.state.suspendedMessage}
                  </DialogContentText>
                </DialogContent>
              </Box>
            </Box>
          </Dialog>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const accountSuspendedModal = {
  container:{
    maxWidth: '100%', 
    width: '480px', 
    display: 'flex', 
    alignItems: 'center',
    justifyContent: "center",
    border: '3px solid black', 
    boxSizing: 'border-box' as "border-box", 
    minHeight: '465px',
  },
  modalContentWrapper:{
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  crossMark:{
    backgroundColor: '#FF5B74',
    border: 'solid 3px #000',
    width: 'fit-content', 
    padding: '12px',
    marginBottom:12
  },
  crossMarkIcon:{
    height: '55px',
    color: 'black',
    width: '55px',
  },
  crossMarkCloseBtn:{
    position: "absolute" as "absolute", 
    top: '3px', 
    right: '3px'
  },
  crossMarkCloseIcon:{ 
    fontWeight: 900, 
    color: "black", 
    fontSize: "25x", 
    width: "25px", 
    height: "25px"
  },
  suspendedTitle:{
    fontSize:30,
    color:"black",
    padding:10,
    fontWeight:700,
    fontFamily: 'CircularStd',
    marginBottom:14
  },
  suspendedMessage:{
    textAlign:"center" as "center",
    fontSize:"14px",
    fontFamily: 'CircularStd',
    color:"#5A5A5A",
    paddingInline:"20px"
  },
}

const NotificationLayout = styled("div")({
  padding: "8px 7px",
  borderRadius: 2,
  marginBottom: 20,
  boxShadow: "0px 8px 32px 0px #0000000F",
  "&:hover": { 
    cursor: "pointer"
  }
});

const SubmitButton = styled(Button)({
  backgroundColor: "#FFF",
  color: "#000",
  border: "solid 3px #000",
  borderRadius: 0,
  textTransform: "none",
  fontSize: "15px",
  width: "100%",
  fontFamily: "CircularStdBold",
  padding: "4px",
  "&:hover": {
    backgroundColor: "#BDB9FB"
  }
});

const webStyles = {
  notificationTitle: {
    fontSize: 14,
    color: "#000",
    fontFamily: "CircularStdBold",
    textTransform: "capitalize" as "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textWrap: "nowrap",
    maxWidth:280,
    fontWeight: 700,
  },
  notificationContentWrap: {
    maxWidth: "75%"
  },
  notificationBodyText: {
    color: "#5A5A5A",
    fontSize: 14,
    fontFamily: "CircularStd",
    fontWeight: 450,
  },
}

const styles = StyleSheet.create({
  profileTitle: {
    color: "#000",
    fontSize: 16,
    fontWeight: "600",
    marginBottom: 5,
    fontFamily: "CircularStdBold"
  },
  flexColumn: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row"
  },
  noNotificationtext: {
    color: "#5A5A5A",
    fontSize: 14,
    lineHeight: 20,
    fontFamily: "CircularStd",
    textAlign:"center",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    margin:20
  },
  approved: {
    color: "#00BFBA",
    fontSize: 16,
    textAlign: "center",
    fontWeight: "600",
    marginTop: 3,
    fontFamily: "CircularStdBold"
  },
  rejected: {
    color: "#FF5B74",
    fontSize: 16,
    textAlign: "center",
    fontWeight: "600",
    marginTop: 3,
    fontFamily: "CircularStdBold"
  },
  status: {
    color: "#FFBA00",
    fontSize: 16,
    textAlign: "center",
    fontWeight: "600",
    marginTop: 3,
    fontFamily: "CircularStdBold"
  },
  imageBorder: {
    marginRight: 10,
    borderColor: "#000",
    borderWidth: 3,
    width: 56,
    height: 56
  },
  cardLayout: {
    height: 293,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: "#000",
    display: "flex",
    position: "relative",
    zIndex: 1,
    justifyContent: "flex-end"
  },
  imgEnquire: {
    height: 156,
    borderBottomColor:"#000",
    borderBottomWidth:3
  },
  cardSubTiitle: {
    fontSize: 18,
    fontWeight: "700",
    paddingTop: 20,
    paddingBottom: 20,
    fontFamily: "CircularStdBold"
  }
});


const titleStyle = { 
  display: "flex", 
  flexDirection: "column", 
  WebkitFontSmoothing: 'none'
};

const cardSubTiitleStyle = {
  fontSize: 18,
  fontWeight: 700,
  paddingTop: 20,
  paddingBottom: 20,
  fontFamily: "CircularStdBold",
  WebkitFontSmoothing: "none"
}

const approvedStyle = {
  fontSize: 14,
  textAlign: "end",
  fontWeight: "600",
  marginTop: 3,
  fontFamily: "CircularStdBold",
  WebkitFontSmoothing: "none",
  textTransform:"capitalize"
};

const SubTitle = styled('div')({
  fontSize: 14,
  color: "#000",
  fontFamily: "CircularStd",
  textOverflow: 'ellipsis', 
  width: '100%', 
  maxWidth: '230px', 
  minWidth: '90px', 
  whiteSpace: 'nowrap', 
  overflow: 'hidden', 

  "@media screen and (max-width: 3650px)": {
    maxWidth: '335px', 
  },
  "@media screen and (max-width: 1750px)": {
    maxWidth: '235px', 
  },
  "@media screen and (max-width: 1550px)": {
    maxWidth: '200px',
  },
  "@media screen and (max-width: 1350px)": {
    maxWidth: '160px',
  },
  "@media screen and (max-width: 1280px)": {
    maxWidth: '130px', 
  },
  "@media screen and (max-width: 1090px)": {
    maxWidth: '100px', 
  },
  "@media screen and (max-width: 950px)": {
    maxWidth: '230px', 
  },
  "@media screen and (max-width: 780px)": {
    maxWidth: '145px', 
  },
  "@media screen and (max-width: 600px)": {
    maxWidth: '230px', 
  },
});

const CustomCompanyLogoDiv = styled('div')({
  minWidth:"66px" ,height:"66px",
  border:"3px black solid",
  objectFit: "cover",
  fontFamily:"CircularStd" , 
  fontWeight:600 , 
  fontSize:36, 
  display: "flex",
  alignItems: "center",
  justifyContent: "center" ,
  "@media screen and (max-width:1550px)": {
    minWidth:55,
    maxHeight:55,
  },
  "@media screen and (max-width:1250px)": {
    minWidth:50,
    maxHeight:50,
  },
  "@media screen and (max-width:1280px)": {
    maxWidth:45,
    maxHeight:45,
  },
})

// Customizable Area End
